import React, { useState, useEffect } from 'react';
import { db, storage } from './firebase'; // Import storage from firebase config
import { doc, setDoc, onSnapshot } from "firebase/firestore"; 
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Import storage functions
import { BrowserRouter as Router, Route, Routes, useParams, useNavigate } from 'react-router-dom';

function Note() {
  const { noteId } = useParams();
  const [note, setNote] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!noteId) {
      const newNoteId = Math.random().toString(36).substring(7);
      navigate(`/${newNoteId}`);
      return;
    }
    
    const noteDoc = doc(db, "notes", noteId);
    const unsubscribe = onSnapshot(noteDoc, (doc) => {
      if (doc.exists()) {
        setNote(doc.data().content);
        setFileUrl(doc.data().fileUrl || null); // Load the file URL if it exists
      }
    });
    return () => unsubscribe();
  }, [noteId, navigate]);

  const handleNoteChange = async (e) => {
    const newContent = e.target.value;
    setNote(newContent);
    await setDoc(doc(db, "notes", noteId), { content: newContent, fileUrl });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const uploadFile = async () => {
    if (!file) return;

    const storageRef = ref(storage, `notes/${noteId}/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadUrl = await getDownloadURL(storageRef);
    
    setFileUrl(downloadUrl);

    // Update Firestore with the file URL
    await setDoc(doc(db, "notes", noteId), { content: note, fileUrl: downloadUrl });
  };

  return (
    <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <textarea
        value={note}
        onChange={handleNoteChange}
        style={{ width: '90%', height: '60%', fontSize: '18px', marginBottom: '20px' }}
      />
      
      <input type="file" onChange={handleFileChange} style={{ marginBottom: '10px' }} />
      <button onClick={uploadFile} style={{ marginBottom: '20px' }}>Upload File</button>

      {fileUrl && (
        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
          View Uploaded File
        </a>
      )}
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:noteId" element={<Note />} />
        <Route path="/" element={<Note />} />
      </Routes>
    </Router>
  );
}

export default App;
