import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc, onSnapshot } from "firebase/firestore"; 
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAeWCC63_yZ3O3Lanau_BhF5v6cs2cSgSQ",
  authDomain: "shared-notepad-95aac.firebaseapp.com",
  projectId: "shared-notepad-95aac",
  storageBucket: "shared-notepad-95aac.appspot.com",
  messagingSenderId: "363194736431",
  appId: "1:363194736431:web:bbb95c36e760f602a38bda"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };